import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

function Seo({ description, lang = "en", meta = [], keywords = [], title }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription =
          description || data.site.siteMetadata.description;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            link={[
              {
                type: `text/plain`,
                rel: `author`,
                href: `${process.env.PUBLIC_URL}/humans.txt`,
              },
              {
                rel: `preconnect`,
                href: `https://in.hotjar.com`,
              },
              {
                rel: `preconnect`,
                href: `https://use.typekit.net`,
              },
              {
                rel: `preconnect`,
                href: `https://www.youtube.com`,
              },
              {
                rel: `preconnect`,
                href: `https://googleads.g.doubleclick.net`,
              },
            ]}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:image`,
                content: `https://mountainlandcovers.com/og-image.jpg`,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: "google-site-verification",
                content: process.env.DISRUPTIVE_SITE_VERIFICATION,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          >
            <script type="application/ld+json">{`
              {
                "@context": "http://schema.org",
                "@type": "WebSite",
                "name": "${data.site.siteMetadata.title}"
              }
            `}</script>
          </Helmet>
        );
      }}
    />
  );
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default Seo;

const detailsQuery = graphql`
  query DefaultSeoQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
