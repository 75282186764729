import React from "react";

import Seo from "../components/seo";

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <div className="container px-4 mx-auto my-16">
      <h1>NOT FOUND</h1>
      <p>That page does not exist</p>
    </div>
  </>
);

export default NotFoundPage;
